import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, tap, map } from 'rxjs';
import _ from 'lodash';
import { oDataService } from '@common/services/odata';
import { Pagination } from '@common/types/pagination.types';
import { environment } from 'environments/environment';
import { Store } from '@common/services/commondata';
import { MaxLengthValidator } from '@angular/forms';

@Injectable({ providedIn: 'root' })
export class StoreService {
    // Private
    private _filters: BehaviorSubject<any> = new BehaviorSubject({});
    private _pagination: BehaviorSubject<Pagination> = new BehaviorSubject(
        null
    );
    private _product: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _products: BehaviorSubject<any[] | null> = new BehaviorSubject(
        null
    );

    private _store: BehaviorSubject<Store[]> = new BehaviorSubject<Store[]>(null);

    private controller: string = 'Store';
    private withJoin: string = 'mall,brands($expand=brand)';
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _oDataService: oDataService
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for filters
     */
    get filters() {
        return this._filters.value;
    }

    /**
     * Setter for filters
     */
    set filters(values: any) {
        this._filters.next(values);
    }

    /**
     * Getter for Pagination
     */
    get pagination() {
        return this._pagination.value;
    }

    /**
     * Setter for Pagination
     */
    set pagination(values) {
        this._pagination.next(values);
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<Pagination> {
        return this._pagination.asObservable();
    }

    /**
     * Getter for product
     */
    get product$(): Observable<any> {
        return this._product.asObservable();
    }

    /**
     * Getter for products
     */
    get products$(): Observable<any[]> {
        return this._products.asObservable();
    }

    /**
     * Getter for Observable Store
     */
    get store$(): Observable<Store[]> {
        return this._store.asObservable();
    }

    /**
     * Getter for Store
     */
    get store(): Store[] {
        return this._store.value;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set BehaviorSubject Store
     * @param value
     */
    setStore$(value: Store[]) {
        this._store.next(value);
    }

    /**
     * Get products
     * @param reload
     */
    getProductsWithPagination(reload?: boolean): Observable<any> {
        if (reload) {
            this.pagination = {
                length: 0,
                page: 0,
                size: 100,
                sort: '',
                order: 'asc',
            };
            this.filters = {};
        }
        return forkJoin({
            products: this.getProducts(this.filters, this.pagination),
        }).pipe(
            tap((response) => {
                this._pagination.next({
                    ...this.pagination,
                    length: response.products['@odata.count'],
                });
                this._products.next(response.products['value']);
            })
        );
    }

    /**
     * Get products
     *
     * @param filters
     * @param pagination
     */
    getProducts(filters, pagination) {
        return this._httpClient.get<any[]>(
            `${environment.oDataUrl}/odata/` +
                this.controller +
                this._oDataService.setFilters(
                    filters,
                    pagination,
                    this.withJoin
                )
        );
    }

    getCommonData() {
        return this.getProducts({ isActive: true }, null).pipe(
             map((response) => {
                let resp = response["value"];
                resp.sort((a, b) => {
                    return a.code < b.code ? -1 : 1;
                 });
                 this._store.next(resp);
             })
         );
     }

    getProductsDefault() {
        return this._httpClient.get<any[]>(
            `${environment.oDataUrl}/api/` +
                this.controller 
        );
    }

    /**
     * download products
     *
     * @param filters
     */
    downloadProducts(filters): Observable<any[]> {
        return this._httpClient
            .get<any[]>(
                `${environment.oDataUrl}/odata/` +
                    this.controller +
                    this._oDataService.setFilters(filters, null, this.withJoin)
            )
            .pipe(
                map((response) => {
                    let data = [];
                    response["value"].forEach(element => {
                        let item = {...element};
                        if (element.brands.length > 0) {
                            element.brands.forEach(childElement => {
                                item.brand = {
                                    code: childElement.brand.code
                                };
                                item.brands = {
                                    name: childElement.brand.levelTwo
                                }
                                data.push(Object.assign({}, item));
                            });
                        }
                        else {
                            data.push(item);
                        }
                    });
                    
                    return data;
                })
            );
    }

    /**
     * Upload product
     *
     * @param products
     */
    uploadProduct(products) {
        let data = _.chain(products)
            .groupBy('code')
            .map((items, code) => {
                let product = ({
                    code: code,
                    name: items[0].name,
                    mallId: items[0].mallId,
                    address: items[0].address,
                    size: items[0].size,
                    brands: items.map(x => { return { brandId: x.brandId }}),
                    isActive: items[0].isActive,
                })
                return product;
            }).value();
        return this._httpClient.post(
            `${environment.oDataUrl}/api/` + this.controller, data,
            {
                reportProgress: true,
                observe: 'events',
            }
        );
    }

    /**
     * Create product
     *
     * @param products
     */
    createProduct(products) {
        this._httpClient.post(`${environment.oDataUrl}/api/` + this.controller, products).subscribe(() => {
            forkJoin({
                items: this.getProductsWithPagination(),
                commondata: this.getCommonData()
            }).subscribe();
        });
    }

    /**
     * Update product
     *
     * @param product
     */
    updateProduct(id: string, product: any) {
        this._httpClient.put(`${environment.oDataUrl}/api/` + this.controller + `/${id}`, product).subscribe(() => {
            forkJoin({
                items: this.getProductsWithPagination(),
                commondata: this.getCommonData()
            }).subscribe();
        });
    }

    getStoresByCountry(countryId) {
        return this._httpClient.get(
            `${environment.oDataUrl}/api/${this.controller}/GetCountryOutlets/${countryId}`
        );
    }
}
