<!-- Button -->
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button mat-menu-item>
        <ng-cotainer *ngIf="localauthService.user$ | async as user">
            <span class="flex flex-col leading-none">
                <span>Signed in as</span>
                <span class="mt-1.5 text-md font-medium">{{user.fullName}}</span>
            </span>
        </ng-cotainer>
    </button>
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="logout()">
        <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
        <span>Sign out</span>
    </button>
</mat-menu>
