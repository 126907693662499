
export const DATE_FORMAT = "dd-MM-YYYY";
export const DATE_FORMAT_TIME = "dd-MM-YYYY h:mm a";
export const DATE_FORMAT_PICKER = "DD-MM-YYYY";
export const DATE_FORMAT_TIME_PICKER = "DD-MM-YYYY h:mm a";

export const DATE_PICKER_FORMAT = {
    parse: {
        dateInput: DATE_FORMAT_PICKER, // this is how your date will be parsed from Input
    },
    display: {
        dateInput: DATE_FORMAT_PICKER, // this is how your date will get displayed on the Input
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

export const DATE_PICKER_TIME_FORMAT = {
    parse: {
        dateInput: DATE_FORMAT_TIME_PICKER,
    },
    display: {
        dateInput: DATE_FORMAT_TIME_PICKER,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};