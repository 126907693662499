import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, ENVIRONMENT_INITIALIZER, isDevMode, inject } from '@angular/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { HttpRequestInterceptor } from '@common/interceptors/httpintercept/http.interceptor';
import { AuthInterceptor } from 'app/core/auth/auth.interceptor';
import { provideFuse } from '@fuse';
import { appRoutes } from 'app/app.routes';
import { provideIcons } from 'app/core/icons/icons.provider';
import { provideTransloco } from 'app/core/transloco/transloco.provider';
import { mockApiServices } from 'app/mock-api';
import { environment } from 'environments/environment';
// Import the module from the SDK
import { provideAuth0, authHttpInterceptorFn } from '@auth0/auth0-angular';
import { APP_BASE_HREF, DatePipe } from '@angular/common';
import { LocalAuthService } from './core/auth/auth.service';
import { AlertService } from '@common/components/alert/alert.service';
import { provideAuth } from './core/auth/auth.provider';

export const appConfig: ApplicationConfig = {
    providers: [
        { provide: APP_BASE_HREF, useValue: '/' },
        { provide: DatePipe },

        provideAuth0({
            domain: environment.domain,
            clientId: environment.clientId,
            authorizationParams: {
                audience: `${environment.oDataUrl}/`,
                redirect_uri: `${window.location.origin}/signed-in-redirect`,
                // Request this scope at user authentication time
                scope: 'read:current_user',
            },
            httpInterceptor: {
                allowedList: 
                    [{
                        uri: `${environment.oDataUrl}/api/Authenticate/LoginAuth0`,
                        tokenOptions: {
                            authorizationParams: {
                                // The attached token should target this audience
                                audience: `${environment.oDataUrl}/`,

                                // The attached token should have these scopes
                                scope: 'read:current_user'
                            }
                        }
                    }]
            }
        }),
        provideAnimations(),
        provideHttpClient(withInterceptors([HttpRequestInterceptor, authHttpInterceptorFn])),
        provideAuth(),
        provideRouter(appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({scrollPositionRestoration: 'enabled'}),
        ),

        // Material Date Adapter
        {
            provide : DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide : MAT_DATE_FORMATS,
            useValue: {
                parse  : {
                    dateInput: 'D',
                },
                display: {
                    dateInput         : 'DDD',
                    monthYearLabel    : 'LLL yyyy',
                    dateA11yLabel     : 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },
        provideServiceWorker('ngsw-worker.js', {
            enabled: !isDevMode(),
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),

        // Transloco Config
        provideTransloco(),

        // Fuse
        provideIcons(),
        provideFuse({
            mockApi: {
                delay   : 0,
                services: mockApiServices,
            },
            fuse   : {
                layout : 'classy',
                scheme : 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme  : 'theme-default',
                themes : [
                    {
                        id  : 'theme-default',
                        name: 'Default',
                    },
                    {
                        id  : 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id  : 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id  : 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id  : 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id  : 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),
    ],
};
