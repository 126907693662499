import { HttpRequest, HttpEvent, HttpResponse, HttpHandlerFn, HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AlertService } from '@common/components/alert/alert.service';
import { Observable, throwError, Subject } from 'rxjs';
import { tap, catchError, finalize } from 'rxjs/operators';

export const HttpRequestInterceptor = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
    const alertService = inject(AlertService);

    return next(request).pipe(tap(evt => {
            if (evt instanceof HttpResponse) {
                if (evt.body && evt.body["messages"]) {
                    
                    alertService.success(evt.body["messages"], null, { autoClose: true });
                }
            }
        }), catchError((err: HttpErrorResponse) => {
            switch (err.status) {
                case 0:
                    alertService.error("Please Check your Network Status!", err.statusText, { autoClose: true });
                    break;
                case 400:
                    if (!Array.isArray(err.error.messages)) {
                        alertService.error(err.error, err.statusText, { autoClose: true });
                    }
                    else {
                        alertService.error(err.error.messages.join("\r\n"), err.statusText, { autoClose: true });                      
                    }
                    return throwError(() => new Error(err.error.messages));
                case 401:
                    alertService.error("Authorization needed!", err.statusText, { autoClose: true });
                    break;
                case 403:
                    alertService.error("Request not allowed!", err.statusText, { autoClose: true });
                    break;
                case 404:
                    if (!Array.isArray(err.error.messages)) {
                        alertService.error(err.error, err.statusText, { autoClose: true });
                    }
                    else {
                        alertService.error(err.error.messages.join("\r\n"), err.statusText, { autoClose: true });
                    }
                    return throwError(() => new Error(err.error.messages));
                case 500:
                    alertService.error("Opps! Server encounter an issue with this request.", err.statusText, { autoClose: true });
                    break;                    
                default:
                    alertService.error("Unknown Error!", err.statusText, { autoClose: true });
                    break;
            }
            // For any error not return above..
            return throwError(() => new Error(err.message));
        }), finalize(() => {
        }))
    }