import { MessagesMockApi } from 'app/mock-api/common/messages/api';
import { NavigationMockApi } from 'app/mock-api/common/navigation/api';
import { NotificationsMockApi } from 'app/mock-api/common/notifications/api';
import { AnalyticsMockApi } from 'app/mock-api/dashboards/analytics/api';

export const mockApiServices = [
    MessagesMockApi,
    NavigationMockApi,
    NotificationsMockApi,
    AnalyticsMockApi
];
