import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { fuseAnimations } from '@fuse/animations';
import { AlertService } from './alert.service';
import { NgFor, NgIf } from '@angular/common';
import { FuseAlertComponent } from '@fuse/components/alert';
import { Alert } from './alert.model';

@Component(
    {
        selector: 'alert',
        templateUrl: './alert.component.html',
        animations: fuseAnimations,
        styleUrls: ['./alert.component.scss'],
        standalone: true,
        imports: [NgFor, NgIf, FuseAlertComponent],
    })

export class AlertComponent implements OnInit, OnDestroy {
    @Input() name = 'default-alert';

    alerts: Alert[] = [];
    alertSubscription: Subscription;
    routeSubscription: Subscription;

    constructor(
        private router: Router,
        private alertService: AlertService) { }

    ngOnInit() {
        // subscribe to new alert notifications
        this.alertSubscription = this.alertService.onAlert(this.name)
            .subscribe(alert => {
                // clear alerts when an empty alert is received
                if (!alert.message) {
                    // filter out alerts without 'keepAfterRouteChange' flag
                    this.alerts = this.alerts.filter(x => x.keepAfterRouteChange);

                    // remove 'keepAfterRouteChange' flag on the rest
                    this.alerts.forEach(x => delete x.keepAfterRouteChange);
                    return;
                }

                // add alert to array
                this.alerts.push(alert);

                // auto close alert if required
                if (alert.autoClose) {
                    setTimeout(() => this.removeAlert(alert), 4500);
                }
            });

        // clear alerts on location change
        this.routeSubscription = this.router.events.subscribe(event => {
            // if (event instanceof NavigationStart) {
            //     this.alertService.clear(this.id);
            // }
        });
    }

    ngOnDestroy() {
        // unsubscribe to avoid memory leaks
        this.alertSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }

    removeAlert(alert: Alert) {
        // check if already removed to prevent error on auto close
        if (!this.alerts.includes(alert)) return;
        // remove alert
        this.alerts = this.alerts.filter(x => x !== alert);

    }
}