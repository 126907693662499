import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, forkJoin, map, tap } from 'rxjs';
import { Brand, CountryCurrency, Mall, ItemCategory, PriceCategory, Color, Warehouse, Store, ItemStyle, Collection, ItemCategoryVariance, ItemSize, Supplier, Tag } from './commondata.types';
import { CountryService } from 'app/modules/warehouse_inventory/swims+/management/country/country.service';
import { BrandService } from 'app/modules/warehouse_inventory/swims+/management/brand/brand.service';
import { MallService } from 'app/modules/warehouse_inventory/swims+/management/mall/mall.service';
import { pageField } from '@common/types/pagefield.types';
import { ItemCategoryService } from 'app/modules/warehouse_inventory/swims+/management/itemcategory/itemcategory.service';
import { SellPriceService } from 'app/modules/warehouse_inventory/swims+/management/sellprice/sellprice.service';
import { ColorService } from 'app/modules/warehouse_inventory/swims+/management/color/color.service';
import { WarehouseService } from 'app/modules/warehouse_inventory/swims+/management/warehouse/warehouse.service';
import { StoreService } from 'app/modules/warehouse_inventory/swims+/management/store/store.service';
import { ItemStyleService } from 'app/modules/warehouse_inventory/swims+/management/itemstyle/itemstyle.service';
import { CollectionService } from 'app/modules/warehouse_inventory/swims+/management/collection/collection.service';
import { ItemCategoryVarianceService } from 'app/modules/warehouse_inventory/swims+/management/itemcategoryvariance/itemcategoryvariance.service';
import { ItemSizeService } from 'app/modules/warehouse_inventory/swims+/management/itemsize/itemsize.service';
import { SupplierService } from 'app/modules/warehouse_inventory/swims+/management/supplier/supplier.service';
import { TagService } from 'app/modules/warehouse_inventory/swims+/management/tag/tag.service';
import { RoleManagementService } from 'app/modules/warehouse_inventory/swims/usermanagement/rolemanagement/rolemanagement.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class CommonDataService {
    private _systemStatues: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
    /**
     * Constructor
     */
    constructor(
        private _countryService: CountryService,
        private _brandService: BrandService,
        private _mallService: MallService,
        private _itemCategoryService: ItemCategoryService,
        private _itemCategoryVarianceService: ItemCategoryVarianceService,
        private _itemStyleService: ItemStyleService,
        private _itemSizeService: ItemSizeService,
        private _supplierService: SupplierService,
        private _sellPriceService: SellPriceService,
        private _collectionService: CollectionService,
        private _tagService: TagService,
        private _colorService: ColorService,
        private _warehouseLocationService: WarehouseService,
        private _storeService: StoreService,
        private _userRoleService: RoleManagementService,
        private _httpClient: HttpClient,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for Observable country and currency
     */
    get countrycurrency$(): Observable<CountryCurrency[]> {
        return this._countryService.countrycurrency$;
    }


    /**
     * Getter for country and currency
     */
    get countrycurrency(): CountryCurrency[] {
        return this._countryService.countrycurrency;
    }

    /**
     * Getter for Observable country and currency
     */
    get brand$(): Observable<Brand[]> {
        return this._brandService.brand$;
    }

    /**
     * Getter for country and currency
     */
    get brand(): Brand[] {
        return this._brandService.brand;
    }

    /**
     * Getter for Observable color
     */
    get color$(): Observable<Color[]> {
        return this._colorService.color$;
    }

    /**
     * Getter for color
     */
    get color(): Color[] {
        return this._colorService.color;
    }

    /**
     * Getter for Observable collection
     */
    get collection$(): Observable<Collection[]> {
        return this._collectionService.collection$;
    }

    /**
     * Getter for collection
     */
    get collection(): Collection[] {
        return this._collectionService.collection;
    }

    /**
     * Getter for Observable country and currency
     */
    get mall$(): Observable<Mall[]> {
        return this._mallService.mall$;
    }

    /**
     * Getter for country and currency
     */
    get mall(): Mall[] {
        return this._mallService.mall;
    }

    /**
     * Getter for Observable store
     */
    get store$(): Observable<Store[]> {
        return this._storeService.store$;
    }

    /**
     * Getter for store
     */
    get store(): Store[] {
        return this._storeService.store;
    }

    /**
     * Getter for Observable ItemCategory
     */
    get itemCategory$(): Observable<ItemCategory[]> {
        return this._itemCategoryService.itemCategory$;
    }

    /**
     * Getter for ItemCategory
     */
    get itemCategory(): ItemCategory[] {
        return this._itemCategoryService.itemCategory;
    }

    /**
     * Getter for Observable ItemCategory
     */
    get itemCategoryVariance$(): Observable<ItemCategoryVariance[]> {
        return this._itemCategoryVarianceService.itemCategoryVariance$;
    }

    /**
     * Getter for ItemCategory
     */
    get itemCategoryVariance(): ItemCategoryVariance[] {
        return this._itemCategoryVarianceService.itemCategoryVariance;
    }

    /**
     * Getter for Observable ItemStyle
     */
    get itemStyle$(): Observable<ItemStyle[]> {
        return this._itemStyleService.itemstyle$;
    }

    /**
     * Getter for ItemStyle
     */
    get itemStyle(): ItemStyle[] {
        return this._itemStyleService.itemstyle;
    }

    /**
     * Getter for Observable ItemStyle
     */
    get itemSize$(): Observable<ItemSize[]> {
        return this._itemSizeService.itemSize$;
    }

    /**
     * Getter for ItemStyle
     */
    get itemSize(): ItemSize[] {
        return this._itemSizeService.itemSize;
    }

    /**
     * Getter for Observable PriceCategory
     */
    get priceCategory$(): Observable<PriceCategory[]> {
        return this._sellPriceService.priceCategory$;
    }

    /**
     * Getter for PriceCategory
     */
    get priceCategory(): PriceCategory[] {
        return this._sellPriceService.priceCategory;
    }

    /**
     * Getter for Observable Warehouse Location
     */
    get warehouseLocation$(): Observable<Warehouse[]> {
        return this._warehouseLocationService.warehouse$;
    }

    /**
     * Getter for Warehouse Location
     */
    get warehouseLocation(): Warehouse[] {
        return this._warehouseLocationService.warehouse;
    }

    /**
     * Getter for Observable Supplier
     */
    get supplier$(): Observable<Supplier[]> {
        return this._supplierService.supplier$;
    }

    /**
     * Getter for Supplier
     */
    get supplier(): Supplier[] {
        return this._supplierService.supplier;
    }

    /**
     * Getter for Observable Tag
     */
    get tag$(): Observable<Tag[]> {
        return this._tagService.tag$;
    }

    /**
     * Getter for Tag
     */
    get tag(): Tag[] {
        return this._tagService.tag;
    }

    /**
    * Getter for Observable country and currency
    */
    get userRoles$(): Observable<any[]> {
        return this._userRoleService.userRoles$;
    }
    /**
     * Getter for country and currency
     */
    get userRoles(): any[] {
        return this._userRoleService.userRoles;
    }
    //setter for systemstatues
    setSystemStatuses$(value: any[]) {
        this._systemStatues.next(value);
    }
    /**
     * Getter for Observable systemstatus
     */
    get systemStatuses$(): Observable<any[]> {
        return this._systemStatues.asObservable();
    }
    /**
     * Getter for systemstatus
     */
    get systemStatuses(): any[] {
        return this._systemStatues.value;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all
     */
    getAll(): Observable<any> {
        return forkJoin({
            countrycurrencies: this._countryService.getCommonData(),
            brands: this._brandService.getCommonData(),
            malls: this._mallService.getCommonData(),
            itemCategories: this._itemCategoryService.getCommonData(),
            itemCategoryVariances: this._itemCategoryVarianceService.getCommonData(),
            itemStyles: this._itemStyleService.getCommonData(),
            itemSizes: this._itemSizeService.getCommonData(),
            suppliers: this._supplierService.getCommonData(),
            priceCategories: this._sellPriceService.getCommonData(),
            colors: this._colorService.getCommonData(),
            collections: this._collectionService.getCommonData(),
            warehouseLocations: this._warehouseLocationService.getCommonData(),
            stores: this._storeService.getCommonData(),
            tags: this._tagService.getCommonData(),
            userRoles: this._userRoleService.getCommonData(),
            systemStatuses:this.getProducts()
        }).pipe(
            tap((response) => {

            })
        );
    }
    getProducts() {
        return this._httpClient.get<any[]>
            (`${environment.oDataUrl}/api/common/systemstatus`).pipe(map((response) => {
                this._systemStatues.next(response["value"]);
            }));
    }
    /**
  * Get for data by name
  * @param 
  */
    getValueByString(name) {
        switch (name) {
            case 'mall':
                return this.mall;
            case 'brand':
                return this.brand;
            case 'countryCurrency':
            case 'countrySellPrices':
            case 'country':
            case 'currency':
                return this.countrycurrency;
            case 'collection':
                return this.collection;
            case 'itemCategory':
                return this.itemCategory;
            case 'itemCategoryVarianceManagement':
                return this.itemCategoryVariance;
            case 'itemStyle':
                return this.itemStyle;
            case 'itemSize':
                return this.itemSize;
            case 'priceCategory':
                return this.priceCategory;
            case 'color':
                return this.color;
            case 'warehouseLocation':
                return this.warehouseLocation;
            case 'store':
                return this.store;
            case 'supplier':
                return this.supplier;
            default:
                return undefined;
        }
    }

    /**
     * Get for data by name
     * @param 
     */
    getObservableByString(name) {
        switch (name) {
            case 'mall':
                return this.mall$;
            case 'brand':
                return this.brand$;
            case 'countryCurrency':
            case 'countrySellPrices':
            case 'country':
            case 'currency':
                return this.countrycurrency$;
            case 'collection':
                return this.collection$;
            case 'itemCategory':
                return this.itemCategory$;
            case 'itemCategoryVarianceManagement':
                return this.itemCategoryVariance$;
            case 'itemStyle':
                return this.itemStyle$;
            case 'itemSize':
                return this.itemSize$;
            case 'priceCategory':
                return this.priceCategory$;
            case 'color':
                return this.color$;
            case 'warehouseLocation':
                return this.warehouseLocation$;
            case 'store':
                return this.store$;
            case 'supplier':
                return this.supplier$;
            default:
                return undefined;
        }
    }

    attachValidator(pagefield: pageField[]): pageField[] {
        return pagefield.map((x) => {
            if (x.joiningKey) {
                x.validator = this.getValueByString(x.colCode.split('.')[0]);
            }
            return x;
        });
    }

}