import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PriceCategory } from '@common/services/commondata';
import { oDataService } from '@common/services/odata';
import { Pagination } from '@common/types/pagination.types';
import { environment } from 'environments/environment';
import { BehaviorSubject, forkJoin, Observable, tap, map } from 'rxjs';
import _ from 'lodash';

@Injectable({ providedIn: 'root' })
export class SellPriceService {
    // Private
    private _filters: BehaviorSubject<any> = new BehaviorSubject({});
    private _pagination: BehaviorSubject<Pagination> = new BehaviorSubject(null);
    private _product: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _products: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
    private controller: string = 'Sellprice';
    private withJoin: string = 'countrySellPrices';

    private _priceCategory: BehaviorSubject<PriceCategory[]> = new BehaviorSubject<PriceCategory[]>(null);
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _oDataService: oDataService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for filters
     */
    get filters() {
        return this._filters.value;
    }

    /**
     * Setter for filters
     */
    set filters(values: any) {
        this._filters.next(values);
    }

    /**
     * Getter for Pagination
     */
    get pagination() {
        return this._pagination.value;
    }

    /**
     * Setter for Pagination
     */
    set pagination(values) {
        this._pagination.next(values);
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<Pagination> {
        return this._pagination.asObservable();
    }

    /**
     * Getter for product
     */
    get product$(): Observable<any> {
        return this._product.asObservable();
    }

    /**
     * Getter for products
     */
    get products$(): Observable<any[]> {
        return this._products.asObservable();
    }

    /**
     * Getter for Observable PriceCategory
     */
    get priceCategory$(): Observable<PriceCategory[]> {
        return this._priceCategory.asObservable();
    }

    /**
     * Getter for PriceCategory
     */
    get priceCategory(): PriceCategory[] {
        return this._priceCategory.value;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set BehaviorSubject PriceCategory
     * @param value
     */
    setPriceCategory$(value: PriceCategory[]) {
        this._priceCategory.next(value);
    }

    /**
     * Get products
     * @param reload
     */
    getProductsWithPagination(reload?: boolean): Observable<any> {
        if (reload) {
            this.pagination = {
                length: 0,
                page: 0,
                size: 100,
                sort: '',
                order: 'asc'
            }
            this.filters = {};
        }
        return forkJoin({
            products: this.getProducts(this.filters, this.pagination),
        }).pipe(
            tap((response) => {
                this._pagination.next({ ...this.pagination, length: response.products["@odata.count"] });
                this._products.next(response.products["value"]);
            })
        );
    }

    /**
     * Get products
     *
     * @param filters
     * @param pagination
     */
    getProducts(filters, pagination) {
        return this._httpClient.get<any[]>(`${environment.oDataUrl}/odata/` + this.controller + this._oDataService.setFilters(filters, pagination, this.withJoin));
    }

    getCommonData() {
        return this.getProducts({ isActive: true }, null).pipe(
             map((response) => {
                 this._priceCategory.next(response["value"]);
             })
         );
     }

    getAllPriceCategories(): Observable<any> {        
        return this._httpClient.get<any[]>(
            `${environment.oDataUrl}/odata/` + this.controller );
    }

    /**
     * download products
     * 
     * @param filters 
     */
    downloadProducts(filters): Observable<any[]> {
        return this._httpClient.get<any[]>(`${environment.oDataUrl}/odata/` + this.controller
            + this._oDataService.setFilters(filters, null, this.withJoin))
            .pipe(
                map((response) => {
                    let data = [];
                    response["value"].forEach(element => {
                        let item = {
                            name: element.name,
                            countrySellPrices: {},
                            isActive: element.isActive
                        }
                        if(element.countrySellPrices.length > 0) {
                            element.countrySellPrices.forEach(childElement => {
                                item.countrySellPrices = {
                                    countryCode: childElement.countryCode, 
                                    symbol : childElement.symbol,
                                    price: childElement.price
                                }
                                data.push(Object.assign({}, item));
                            });
                        }
                        else {
                            data.push(item);
                        }
                    });
                    return data;
                })
            );
    }

    /**
     * Upload product
     * 
     * @param products
     */
    uploadProduct(products) {
        let data = _.chain(products)
            .groupBy('name')
            .map((countrySellPrices, name) => {
                let product = ({
                    name: name,
                    countrySellPrices: countrySellPrices.map(x=> {
                        return {
                            countryCurrencyId: x.countryCurrencyId,
                            price:  x['countrySellPrices.price']
                        }
                    }),
                    isActive: countrySellPrices[0].isActive,
                })
                return product;
            })
            .value();

        return this._httpClient.post(`${environment.oDataUrl}/api/` + this.controller, data, {
            reportProgress: true,
            observe: 'events'
        });
    }

    /**
     * Create product
     * 
     * @param products
     */
    createProduct(products) {
        this._httpClient.post(`${environment.oDataUrl}/api/` + this.controller, products).subscribe(() => {
            forkJoin({
                items: this.getProductsWithPagination(),
                commondata: this.getCommonData()
            }).subscribe();
        });
    }

    /**
     * Update product
     *
     * @param product
     */
    updateProduct(id: string, product: any) {
        this._httpClient.put(`${environment.oDataUrl}/api/` + this.controller + `/${id}`, product).subscribe(() => {
            forkJoin({
                items: this.getProductsWithPagination(),
                commondata: this.getCommonData()
            }).subscribe();
        });
    }

    getPriceCategoryByName(name: string) {
        return this.priceCategory.find(x => x.name == name);
    }

    getPriceCategoryById(id: number) {
        return this.priceCategory.find(x => x.id == id);
    }

    getSellPriceByPriceCatId_SellPriceId(priceCategoryId: number, sellPriceId: number) {
        let priceCategory = this.getPriceCategoryById(priceCategoryId);
        if(priceCategory) {
            let sellPrice = priceCategory.countrySellPrices.find(x=> x.sellPriceId === sellPriceId);
            return sellPrice;
        }
        return undefined;
    }
}
