export enum ModuleType {
    BRAND_MANAGEMENT = 'WB_BRND',
    ITEM_CATEGORY_VARIANCE_MANAGEMENT = 'WB_CGVN',
    COLLECTION_MANAGEMENT = 'WB_CLTN',
    COUNTRY_MANAGEMENT = 'WB_CNTY',
    COLOR_MANAGEMENT = 'WB_COLR',
    ITEM_CATEGORY_MANAGEMENT = 'WB_CTGY',
    DASHBOARD = 'WB_DSBD',
    EXCHANGE_RATE_MANAGEMENT = 'WB_EXRT',
    EDIT_CANCEL_FINALIZE_PO = 'WB_FINZ',
    GOODS_INBOUND = 'WB_GSIB',
    ITEM_MASTER_MANAGEMENT = 'WB_IMTR',
    ITEM_STYLE_MANAGEMENT = 'WB_ISTY',
    ITEM_SIZE_MANAGEMENT = 'WB_ISZE',
    MALL_MANAGEMENT = 'WB_MALL',
    MEASUREMENT_MANAGEMENT = 'WB_MSRM',
    ORDER_PLANNING = 'WB_ODPN',
    PALLET_MANAGEMENT = 'WB_PALT',
    PALLET_MOVEMENT = 'WB_PLMV',
    NEW_REPEAT_ORDER_CREATION = 'WB_PONW',
    SUPPLIER_ACCEPTANCE = 'WB_POSA',
    PRINT_TAGS = 'WB_PRTG',
    PRINTING = 'WB_PRTN',
    REPORTS = 'WB_REPT',
    REVERT_FINALIZED_PO = 'WB_RFNZ',
    ROLE_MANAGEMENT = 'WB_ROLM',
    SELL_PRICE_MANAGEMENT = 'WB_SEPC',
    SUPPLIER_MANAGEMENT = 'WB_SPLR',
    STOCK_ADJUSTMENT = 'WB_STAJ',
    APPROVAL_FUNCTION = 'WB_STAP',
    STORE_MANAGEMENT = 'WB_STRO',
    STOCK_RETURN = 'WB_STRT',
    STOCK_TRANSFER = 'WB_STTF',
    TAG_MANAGEMENT = 'WB_TAGM',
    TAG_GENERATION = 'WB_TGGN',
    USER_AND_ROLE_MANAGEMENT = 'WB_URRL',
    USER_MANAGEMENT = 'WB_USRM',
    WAREHOUSE_LOCATION_MANAGEMENT = 'WB_WHLO',
    TUNNEL_SCANNING = 'WB_TNSC',
    PO_PRINTING = "WB_POPR",


    MOBILE_CycleCount = "MB_CYCN",
    MOBILE_GoodsInbound = "MB_GSIB",
    MOBILE_InStorePicking = "MB_ISPK",
    MOBILE_InStoreReplenishment = "MB_ISRP",
    MOBILE_PickingJobsOutlet = "MB_PJOL",
    MOBILE_AssignPickerOutlet = "MB_PJOP",
    MOBILE_PickingJobsWarehouse = "MB_PJWH",
    MOBILE_AssignPickerWarehouse = "MB_PJWP",
    MOBILE_PalletMovement = "MB_PLMV",
    MOBILE_ApprovalOutlet = "MB_RJOA",
    MOBILE_ReceivingJobsOutlet = "MB_RJOL",
    MOBILE_ApprovalWarehouse = "MB_RJWA",
    MOBILE_ReceivingJobsWarehouse = "MB_RJWH",
    MOBILE_ScanTag = "MB_SCTG",
    MOBILE_SearchLocate = "MB_SRLC",
    MOBILE_StockAdjustment = "MB_STAJ",
    MOBILE_StockMovement = "MB_STMV",
    MOBILE_StockTake = "MB_STTK"
}