import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AlertComponent } from '@common/components/alert/alert.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { Subject, takeUntil } from 'rxjs';
import { LocalAuthService } from './core/auth/auth.service';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    standalone : true,
    imports    : [RouterOutlet, AlertComponent],
})
export class AppComponent {

    // Private
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private bnIdle: BnNgIdleService, 
        private authService: LocalAuthService
    )
    {
        //Session Timeout
        this.bnIdle.startWatching(18000).pipe(takeUntil(this._unsubscribeAll)).subscribe((isTimedOut: boolean) => {
            
            // Sign out
            this.authService.signOut();
        });
    }
}
