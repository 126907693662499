import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { authGuardFn } from '@auth0/auth0-angular';
import { LayoutComponent } from 'app/layout/layout.component';
import { AuthGuard } from './core/auth/guards/auth.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/dashboards/project'
    { path: '', pathMatch: 'full', redirectTo: '/dashboards/analytics' },

    { path: 'signed-in-redirect', 
      canActivate: [authGuardFn], 
      canActivateChild: [authGuardFn], 
      loadChildren: () => import('app/modules/auth/signed-in-redirect/signed-in-redirect.routes') 
    },
   
    // swims routes
    {
        path: '',
        canActivate: [AuthGuard], 
        canActivateChild: [AuthGuard], 
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [

            // Dashboards
            {
                path: 'dashboards', children: [
                    { path: 'analytics', loadChildren: () => import('app/modules/warehouse_inventory/dashboards/analytics/analytics.routes') },
                ]
            },
            {
                path: 'swims-common', children: [
                    { path: 'printing', loadChildren: () => import('app/modules/warehouse_inventory/swims/printing/printing.routes') },
                    { path: 'tunnelscanning', loadChildren: () => import('app/modules/warehouse_inventory/swims/tunnelscanning/tunnelscanning.routes') },
                    { path: 'stockadjustment', loadChildren: () => import('app/modules/warehouse_inventory/swims/stockadjustment/stockadjustment.routes') },
                    { path: 'report', loadChildren: () => import('app/modules/warehouse_inventory/swims/report/report.routes') },
                    { path: 'user', loadChildren: () => import('app/modules/warehouse_inventory/swims/usermanagement/usermanagement.routes') },
                ]
            },
            // SWIMS+
            {
                path: 'swims', children: [
                    { path: 'orderplanning', loadChildren: () => import('app/modules/warehouse_inventory/swims+/orderplanning/orderplanning.routes') },
                    { path: 'stocktransfer', loadChildren: () => import('app/modules/warehouse_inventory/swims+/stocktransfer/stocktransfer.routes') },
                    { path: 'management', loadChildren: () => import('app/modules/warehouse_inventory/swims+/management/management.routes') },

                ]
            },
            {
                path: 'access-denied',
                pathMatch: 'full', 
                loadChildren: () => import('app/modules/error/access-denied/access-denied.route')
            },
            {
                path: 'locked-user',
                pathMatch: 'full', 
                loadChildren: () => import('app/modules/error/locked-user/locked-user.route')
            },
            // 404 & Catch all
            { path: '404-not-found', pathMatch: 'full', loadChildren: () => import('app/modules/error/error-404/error-404.routes') },
            { path: '**', redirectTo: '404-not-found' }
        ]
    }
];
