import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigationItem } from '@fuse/components/navigation';
import { UserService } from 'app/core/user/user.service';

@Injectable({ providedIn: 'root' })
export class UserModuleAccessService {
    private navigationList: FuseNavigationItem[]; // flatten list
    private accessibleModules: string[];
    constructor(
        private http: HttpClient,
        private _fuseNavigationService: FuseNavigationService,
        private _userService: UserService,
        private router: Router,

    ) {
        this.navigationList = [];
        _userService.user$.subscribe(u => {
            this.accessibleModules = u.accessibleModules;
        })
    }

    public get currentNavigationListValue() {
        // this._fuseNavigationService.registerComponent()
        return this.navigationList;
    }

    getAllModuleByUserId(userid: number, fromLoginPage: boolean) {

        // return this.http.get<FuseNavigationItem[]>(`${environment.customUrl}/userModuleAccess/getNavigationByUserId/${userid}`)
        //     .pipe(first())
        //     .subscribe(
        //         result => {
        //             let data = [];
        //             data.push(result);
        //             this.currentNavigationListSubject.next(this.getNavigationList(data[0].children));
        //             this.navigationListSubject.next(this.currentNavigationListValue);

        //             //Route to the first in the list
        //             if (fromLoginPage) {
        //                 this.router.navigate([data[0].children[0].children[0].url]);
        //                 localStorage.setItem('current', data[0].children[0].children[0].url);
        //             }
        //             else {
        //                 //
        //                 this.router.navigate([localStorage.getItem('current')]);
        //             }
        //         });

    }

    getNavigationList(item: FuseNavigationItem[]) {
        item.forEach(element => {
            !element.link ? this.getNavigationList(element.children) : this.navigationList.push(element);
        });
    }
    getFilterNavList(items: FuseNavigationItem[]): FuseNavigationItem[] {
        return items
            .map((element) => {
                if (this.isAccessible(element.meta)) {
                    const newElement = { ...element }; // Create a shallow copy of the element
                    if (newElement.children) {
                        newElement.children = this.getFilterNavList(newElement.children); // Recursively filter children
                    }
                    return newElement;
                }
                return null;
            })
            .filter(element => element !== null); // Filter out null values
    }

    isAccessible(meta): boolean {
        if (meta) {
            var result = meta.modules.some(m => this.accessibleModules.includes(m));
            return result;
        }
        return true;

    }
    getNavigationByURL(url) {
        return this.navigationList.find(element => element.link === url);
    }

    getNavigationByCurrentURL() {
        return this.navigationList.find(element => element.link === this.router.url);
    }

    getAllNavigationByCurrentURL() {
        return this.navigationList.filter(element => element.link === this.router.url);
    }

    existFeatures(array, feature: string) {
        return array.find(element => element.pageFeatureName === feature);
    }

    // existRoles(feature: string) {
    //     let main = this._fuseNavigationService.getCurrentNavigation();
    //     if(main && main.length > 0)
    //         return main[0].roles.find(element => element === feature)
    //     else
    //         return false;
    // }

    getURLList(item) {
        let urlList = [];
        item.forEach(element => {
            urlList.push(element.url);
        });
        return urlList;
    }
}