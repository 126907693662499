import { FuseAlertAppearance, FuseAlertType } from "@fuse/components/alert";

export class Alert {
    name: string;
    appearance: FuseAlertAppearance;
    dismissed: boolean;
    dismissible: boolean;
    type: FuseAlertType;
    message: string;
    title?: string;
    autoClose: boolean;
    keepAfterRouteChange: boolean;

    constructor(init?:Partial<Alert>) {
        Object.assign(this, init);
    }
}