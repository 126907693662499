import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';
import { AuthInterceptor } from 'app/core/auth/auth.interceptor';
import { LocalAuthService } from 'app/core/auth/auth.service';

export const provideAuth = (): Array<Provider | EnvironmentProviders> =>
{
    return [
        provideHttpClient(withInterceptors([AuthInterceptor])),
        {
            provide : ENVIRONMENT_INITIALIZER,
            useValue: () => inject(LocalAuthService),
            multi   : true,
        },
    ];
};
