import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Alert } from './alert.model';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<Alert>();
    private defaultId = 'default-alert';

    // enable subscribing to alerts observable
    onAlert(name = this.defaultId): Observable<Alert> {
        return this.subject.asObservable().pipe(filter(x => x && x.name === name));
    }

    // convenience methods
    primary(message: string, title?: string, options?: any) {
        this.alert(new Alert({ ...options, appearance: 'fill', dismissed: false, dismissible: true, type: 'primary', message, title }));
    }

    accent(message: string, title?: string, options?: any) {
        this.alert(new Alert({ ...options, appearance: 'fill', dismissed: false, dismissible: true, type: 'accent', message, title }));
    }

    warn(message: string, title?: string, options?: any) {
        this.alert(new Alert({ ...options, appearance: 'fill', dismissed: false, dismissible: true, type: 'warn', message, title }));
    }

    basic(message: string, title?: string, options?: any) {
        this.alert(new Alert({ ...options, appearance: 'fill', dismissed: false, dismissible: true, type: 'basic', message, title }));
    }

    success(message: string, title?: string, options?: any) {
        this.alert(new Alert({ ...options, appearance: 'fill', dismissed: false, dismissible: true, type: 'success', message, title }));
    }

    error(message: string, title?: string, options?: any) {
        this.alert(new Alert({ ...options, appearance: 'fill', dismissed: false, dismissible: true, type: 'error', message, title }));
    }

    info(message: string, title?: string, options?: any) {
        this.alert(new Alert({ ...options, appearance: 'fill', dismissed: false, dismissible: true, type: 'info', message, title }));
    }

    warning(message: string, title?: string, options?: any) {
        this.alert(new Alert({ ...options, appearance: 'fill', dismissed: false, dismissible: true, type: 'warning', message, title }));
    }

    // main alert method    
    alert(alert: Alert) {
        alert.name = alert.name || this.defaultId;
        this.subject.next(alert);
    }

    // clear alerts
    clear() {
        this.subject.next(null);
    }
}