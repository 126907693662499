import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Pagination } from '@common/types/pagination.types';
import {formatDate} from '@angular/common';

@Injectable({ providedIn: 'root' })
export class oDataService {
    constructor(
        private datepipe: DatePipe,
    ) 
    {}

    formatDateStr(paramDate : Date)
    {
        return formatDate(paramDate,'yyyy-MM-dd','en-US');

    }
    setDateRangeFilter(key : string, value : any)
    {
        let filterString = "";
        let operator = "";
        let actualKey = "";
        let valueStr = "";

        if (key.endsWith("__From"))
        {
            actualKey = key.replace("__From","");
            operator = "ge";
            valueStr = this.formatDateStr(value);
        }
        else
        {
            actualKey = key.replace("__To","");
            operator = "lt";
            valueStr = this.formatDateStr(value);
            let date = new Date(valueStr);
            date.setDate(date.getDate() + 1);
            valueStr = this.formatDateStr(date);

        }
        filterString = `(${actualKey} ${operator} ${valueStr})`;
        return filterString;
    }
    setFilters(filters: {}, pagination:Pagination, withJoin?: string): string {
        let url = '?';
        let items = Object.entries(this.removeDefaultValues(filters));
        if (items.length > 0) {
            url = url.concat("$filter=");
            items.forEach(([key, value], index) => {       
                if (key.includes('.'))
                    key = key.replace('.','/')
                switch (typeof value) {
                    case 'object':
                        if(key.endsWith("__From") || key.endsWith("__To"))
                            url = url.concat(this.setDateRangeFilter(key, value), this.appendAnd(items.length - 1, index));                        
                        break;
                    case 'string':
                            url = url.concat("(contains(", key, ",'", value, "') eq true)", this.appendAnd(items.length - 1, index));
                        break;
                    case 'number':
                        url = url.concat("(", key, " eq " + value, ")", this.appendAnd(items.length - 1, index));
                        break;
                    case 'boolean':
                        url = url.concat(key, " eq " + value, this.appendAnd(items.length - 1, index));
                        break;
                    default:
                        break;
                };
            });
        }
        if (withJoin) {
            url = url.concat("&$expand=", withJoin);
        }

        if (pagination) {
            url = url.concat("&$top=" + pagination.size, "&$skip=" + pagination.size * pagination.page);

            if (pagination.sort) {
                url = url.concat("&$orderby=", pagination.sort.replace('.','/'), " ", pagination.order);
            }
            
            url = url.concat("&$count=true");
        }
        return url;
    }

    appendAnd(itemsLength, index): string {
        if (index === itemsLength)
            return '';
        else
            return ' and ';
    }

    removeDefaultValues(filters) {
        return Object.entries(filters)
            .filter(([key, value]) =>
                value !== '' && value !== null)
            .reduce((acc, [key, value]) => {
                acc[key] = filters[key];
                return acc;
            }, {});
    }
}