import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { oDataService } from "@common/services/odata";
import { Pagination } from "@common/types/pagination.types";
import { environment } from "environments/environment";
import { BehaviorSubject, forkJoin, map, Observable, tap } from "rxjs";
import { AppModule, CreateUserRoleReq, EditUserRoleReq } from "./accessmanagement.model";

@Injectable({ providedIn: 'root' })
export class RoleManagementService {
   
/**
     * Set BehaviorSubject role
     * @param value
     */

    // Private
    private _filters: BehaviorSubject<any> = new BehaviorSubject({});
    private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);
    private _stockAdjustmentForm: BehaviorSubject<any> = new BehaviorSubject(null);
    private _product: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _products: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
    private _roles: BehaviorSubject<any[]> = new BehaviorSubject<any[]>(null);
    // private _adjustmentType: BehaviorSubject<CodeName[]> = new BehaviorSubject<CodeName[]>(null);
    // private _adjustmentAllReason: BehaviorSubject<StockAdjustmentReason[]> = new BehaviorSubject<StockAdjustmentReason[]>(null);
    // private _adjustmentReason: BehaviorSubject<StockAdjustmentReason[]> = new BehaviorSubject<StockAdjustmentReason[]>(null);

    private controller: string = 'UserRole';
   
   
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _oDataService: oDataService,
        private _formBuilder: UntypedFormBuilder,
    ) {
    }
    get filters() {
        return this._filters.value;
    }

    /**
     * Setter for filters
     */
    set filters(values: any) {
        this._filters.next(values);
    }

    /**
     * Getter for Pagination
     */
    get pagination() {
        return this._pagination.value;
    }

    /**
     * Setter for Pagination
     */
    set pagination(values) {
        this._pagination.next(values);
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<Pagination> {
        return this._pagination.asObservable();
    }

    /**
     * Getter for product
     */
    get product$(): Observable<any> {
        return this._product.asObservable();
    }

    /**
     * Getter for products
     */
    get products$(): Observable<any> {
        return this._products.asObservable();
    }
   
    setUserRole$(value: any[]) {
        this._roles.next(value);
    }
     /**
     * Getter for Observable userRoles
     */
     get userRoles$(): Observable<any[]> {
        return this._roles.asObservable();
    }

    /**
     * Getter for userRoles
     */
    get userRoles(): any[] {
        return this._roles.value;
    }
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get products
     * @param reload
     */
    getProductsWithPagination(reload?: boolean): Observable<any> {
        if (reload) {
            this.pagination = {
                length: 0,
                page: 0,
                size: 100,
                sort: '',
                order: 'asc'
            }
            this.filters = {};
        }
        return forkJoin({
            products: this.getProducts(this.filters, this.pagination),
        }).pipe(
            tap((response) => {
                //console.log("a",response)
                this._pagination.next({ ...this.pagination, length: response.products["@odata.count"] });
                this._products.next(response.products["value"]);
            })
        );
    }
    /**
* Get products
*
* @param filters
* @param pagination
*/
    getProducts(filters, pagination) {
        return this._httpClient.get<any[]>
            (`${environment.oDataUrl}/odata/` + this.controller +
                this._oDataService.setFilters(filters, pagination));
    }
    getCommonData() {
        return this.getProducts({ isActive: true }, null).pipe(
             map((response) => {
                 this._roles.next(response["value"]);
             })
         );
     }
    createRole(userRoleReq: CreateUserRoleReq[]) {
        return this._httpClient.post
            (`${environment.oDataUrl}/api/` + this.controller, userRoleReq).subscribe(()=>{
                this.getProductsWithPagination().subscribe();
                this.getCommonData().subscribe();
            });
    }
    updateRole(id, userRoleReq: EditUserRoleReq) {
        return this._httpClient.put
            (`${environment.oDataUrl}/api/` + this.controller + `/${id}`, userRoleReq).subscribe(()=>{
                this.getProductsWithPagination().subscribe();
                this.getCommonData().subscribe();
            } );
    }

    getAllModules(): Observable<AppModule[]> {
        return this._httpClient.get<AppModule[]>(`${environment.oDataUrl}/api/AppModule`).pipe(
            map(modules => modules.map(module => ({ ...module, selected: false,disabled:false })))
        );
    }

    downloadProducts(arg0: any) {

    }
    uploadProduct(product: any) {

    }

}