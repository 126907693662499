// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    appVersion: 'v' + require('../../package.json').version,
    domain: 'dev-5ec4hvzbfso8cn7y.au.auth0.com',
    clientId: 'NI1c9QpkROc634BUQ7VB53DMUQGCLd98', 
    customUrl: 'https://dev-api-custom.cityblue.com.sg/api',
    //oDataUrl: 'http://localhost:5093',
    oDataUrl: "https://dev-api-odata.cityblue.com.sg",
    apiPrinterService: 'http://localhost:5000',

    flexmonsterKey: 'Z7EF-XHA12Q-2U4X1U-1T0V2N-3O6050-240G3Z-0V390C-5Q3Q15-202Y1Y-4G4C6C-062K5P-241G11-1L',
    
    // SignalR Auth0 Configuration
    signalRAuthUrl: 'https://dev-5ec4hvzbfso8cn7y.au.auth0.com/oauth/token',
    signalRClientId: 'BcBouH4WDtryKwLs9yo8J0eowf1xvOFk',
    signalRClientSecret: 'pWSuhQDHmIntJVZcmww71uq2bksLS4FSrNKj--5zD8NRn-k-N6nsIHOSLVMJ5Mvc',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
